<template>
  <nav
    class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow"
  >
    <!-- Sidebar Toggle (Topbar) -->
    <button
      id="sidebarToggleTop"
      class="btn btn-link d-md-none rounded-circle mr-3"
    >
      <i class="fa fa-bars"></i>
    </button>
    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
      <!-- Nav Item - Search Dropdown (Visible Only XS) -->
      <li class="nav-item dropdown no-arrow mx-1">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="alertsDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fas fa-bell fa-fw"></i>
          <!-- Counter - Alerts -->
          <span class="badge badge-danger badge-counter"
            >+{{ this.notificationsLength }}</span
          >
        </a>
        <!-- Dropdown - Alerts -->
        <div
          class="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
          aria-labelledby="alertsDropdown"
        >
          <h6 class="dropdown-header">Notifications</h6>
          <a
            v-for="(notification, index) in notifications"
            @click="viewTicket(notification)"
            class="dropdown-item d-flex align-items-center"
            href="#"
          >
            <div class="mr-3">
              <div class="icon-circle bg-info">
                <i class="fas fa-ticket-alt text-white"></i>
              </div>
            </div>
            <div>
              <div class="small text-gray-500">
                {{ notification.createdAt }}
              </div>
              <span class="font-weight-bold">{{ notification.text }}!</span>
            </div>
            <input
              type="checkbox"
              class="ml-auto"
              @click="markRead(notification)"
            />
          </a>
          <div
            v-if="notifications?.length === 0"
            class="d-flex justify-content-center align-items-center pt-3"
          >
            <div>
              <img
                class="text-center"
                style="height: 100px; width: 100px"
                :src="require('@/assets/notification.png')"
                alt="LOGO"
              />
              <p class="font-weight-bold">No Notification!</p>
            </div>
          </div>
          <!-- <a class="dropdown-item d-flex align-items-center" href="#">
            <div class="mr-3">
              <div class="icon-circle bg-success">
                <i class="fas fa-edit text-white"></i>
              </div>
            </div>
            <div>
              <div class="small text-gray-500">December 7, 2019</div>
              There's an update on ticket number BOIT-007009!
            </div>
          </a>
          <a class="dropdown-item d-flex align-items-center" href="#">
            <div class="mr-3">
              <div class="icon-circle bg-warning">
                <i class="fas fa-exclamation-triangle text-white"></i>
              </div>
            </div>
            <div>
              <div class="small text-gray-500">December 2, 2019</div>
              Ticket number BOIT-007009 is past turnover!
            </div>
          </a>
          <a class="dropdown-item d-flex align-items-center" href="#">
            <div class="mr-3">
              <div class="icon-circle bg-secondary">
                <i class="fas fa-robot text-white"></i>
              </div>
            </div>
            <div>
              <div class="small text-gray-500">December 2, 2019</div>
              Ticketing System has been approved!
            </div>
          </a>
          <a class="dropdown-item d-flex align-items-center" href="#">
            <div class="mr-3">
              <div class="icon-circle bg-info">
                <i class="fas fa-info text-white"></i>
              </div>
            </div>
            <div>
              <div class="small text-gray-500">December 2, 2019</div>
              Ticket number BOIT-007009 has changed status!
            </div>
          </a> -->
          <router-link
            class="dropdown-item text-center small text-gray-500"
            to="/notifications"
            >Show All Notifications</router-link
          >
        </div>
      </li>

      <div class="topbar-divider d-none d-sm-block"></div>
      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow">
        <a
          class="nav-link dropdown-toggle"
          href="#"
          id="userDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="mr-2 d-none d-lg-inline text-gray-600 small"
            >{{ currentUser.first_name }} {{ currentUser.last_name }}
          </span>
          <img
            class="img-profile rounded-circle"
            src="img/undraw_profile.svg"
          />
        </a>
        <!-- Dropdown - User Information -->
        <div
          class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
          aria-labelledby="userDropdown"
        >
          <router-link class="dropdown-item" to="/profile"
            ><i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
            Profile</router-link
          >
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item"
            href="#"
            data-toggle="modal"
            data-target="#logoutModal"
          >
            <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
            Logout
          </a>
        </div>
      </li>
    </ul>
  </nav>
  <!-- Logout Modal-->
  <div
    class="modal fade"
    id="logoutModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
          <button
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          Select "Logout" below if you are ready to end your current session.
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-dismiss="modal">
            Cancel
          </button>
          <a class="btn btn-primary" href="/login" @click="logout">Logout</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import {
  CURRENT_USER_MUTATION,
  LOGOUT_MUTATION,
  MARK_NOTIFICATION,
  ALL_NOTIFICATIONS_QUERY,
} from "@/graphql";
import TokenService from "@/services/token.service.js";
import { parse } from "graphql";

export default {
  name: "Topbar",
  data() {
    return {
      loading: false,
      message: "",
      currentUser: [],
      notifications: [],
      user_id: "",
      notificationsLength: 0,
    };
  },
  apollo: {
    notifications: {
      query: ALL_NOTIFICATIONS_QUERY,
      variables() {
        this.currentUser = JSON.parse(localStorage.getItem("user"));

        this.user_id = this.currentUser.id;
        console.log(this.currentUser, "user_id");
        return {
          limit: 5,
          offset: 0,
          filter: {
            read: false,
            employee: parseInt(this.user_id),
          },
        };
      },
    },
  },

  methods: {
    logout() {
      // this.$apollo
      //   .query({
      //     query: LOGOUT_MUTATION,
      //   })
      //   .then((response) => {
      //     localStorage.clear();
      //   })
      //   .catch((error) => {
      //     console.log(error.message);
      //   });
      localStorage.clear();
      // this.$router.push("/login");
    },
    viewTicket(notification) {
      const notificationID = notification.source_id;
      this.$router.push(`/view-ticket/${notificationID}/`);
      localStorage.setItem("ticket", JSON.stringify(notificationID));
      console.log(notificationID);
    },
    markRead(notification) {
      this.$apollo
        .mutate({
          mutation: MARK_NOTIFICATION,
          variables: {
            markNotificationAsReadId: notification.id,
          },
        })
        .then((response) => {
          this.$swal({
            title: "Marked as seen successfully",
            position: "top-end",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
          this.$apollo.queries.notifications.refetch();
        })
        .catch((error) => {
          this.$swal({
            title: error.message,
            position: "top-end",
            icon: "warning",
            showConfirmButton: false,
            timer: 3000,
          });
        });
    },
    async fetchNotifications() {
      this.currentUser = JSON.parse(localStorage.getItem("user"));
      this.user_id = this.currentUser.id;

      try {
        const response = await this.$apollo.query({
          query: ALL_NOTIFICATIONS_QUERY,
          variables: {
            limit: 5,
            offset: 0,
            filter: {
              employee: parseInt(this.user_id),
              read: false,
            },
          },
        });

        // Assuming response.data.notifications is an array of notifications
        this.notifications = response.data.notifications;
        if (
          response.data &&
          response.data.notifications &&
          response.data.notifications.length > 0
        ) {
          this.notificationsLength = response.data.notifications?.length;
        } else {
          this.notificationsLength = 0;
        }
        // Now you can get the length of notifications
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    },
  },
  async mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.fetchNotifications();
    this.user_id = this.currentUser.id;
    this.username = this.currentUser.username;
    this.first_name = this.currentUser.first_name;
    this.last_name = this.currentUser.last_name;
    this.email = this.currentUser.email;
    this.phone_number = this.currentUser.phone_number;
    this.user_id = this.currentUser.id;
    this.role = localStorage.getItem("role");
  },
};
</script>
