import { createApp, provide, h } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createApolloProvider } from "@vue/apollo-option";
import { setContext } from "apollo-link-context";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "@/assets/css/sb-admin-2.min.css";
// import "@/assets/css/all.min.css";
// /* import jQuery/$ for plugins */
import jQuery from "jquery";
window.jQuery = jQuery;
window.$ = jQuery;

require("@/assets/vendor/jquery/jquery.min.js");
require("@/assets/vendor/bootstrap/js/bootstrap.bundle.min.js");
require("@/assets/vendor/jquery-easing/jquery.easing.min.js");
import "@/assets/css/sb-admin-2.min.css";
require("@/assets/css/fonts.min.css");

const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: "https://ticketing.innovativetollsolution.com/api",
  credentials: "include",
});

// Cache implementation
const cache = new InMemoryCache();

const authLink = setContext((_, { headers }) => {
  // get the authentication token from localstorage if it exists
  const token = localStorage.getItem("token");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : null,
    },
  };
});
// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  // link: httpLink,
  cache,
  credentials: "include",
});

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
})
  .use(router)
  .use(store)
  .use(apolloProvider)
  .use(VueSweetalert2)
  .mount("#app");
